import { formatMoney } from '@shopify/theme-currency';

const selectors = {
    wrapper: '[data-added-to-cart-modal-wrapper]',
    modal: '[data-added-to-cart-modal]',
    details: '[data-product-details]',
    image: '[data-details-image]',
    title: '[data-details-title]',
    type: '[data-details-type]',
    message: '[data-details-message]',
    price: '[data-details-price]',
    options: '[data-details-options]',
    quantityCount: '[data-details-quantity] [data-quantity-count]',
};

const $addedToCartModalWrapper = document.querySelector(selectors.wrapper);
const $addedToCartModal = document.querySelector(selectors.modal);
const $title = document.querySelector(selectors.title);
const $image = document.querySelector(selectors.image);
const $message = document.querySelector(selectors.message);
const $price = document.querySelector(selectors.price);
const $options = document.querySelector(selectors.options);
const $quantityCount = document.querySelector(selectors.quantityCount);

function show() {
    $addedToCartModal.setAttribute('data-modal-state', 'open');
    $addedToCartModal.setAttribute('aria-hidden', 'false');
    $addedToCartModalWrapper.setAttribute('data-blur', 'true');
    $addedToCartModalWrapper.setAttribute('data-state', 'visible');
    document.body.style.overflowY = 'hidden';
}
function hide() {
    $addedToCartModal.setAttribute('data-modal-state', 'closed');
    $addedToCartModal.setAttribute('aria-hidden', 'true');
    $addedToCartModalWrapper.setAttribute('data-blur', 'false');
    $addedToCartModalWrapper.setAttribute('data-state', 'hidden');
    document.body.style.overflowY = '';
}

function resetPreviousProductDetails() {
    $message.innerText = '';
    $price.innerText = '';
    $options.innerHTML = '';
}

function buildProductDetails(product) {
    const imageFileTypes = ['.jpg', '.png', '.webp', '.gif'];
    let imageUrl;
    let price = product.final_line_price;

    imageFileTypes.forEach(type => {
        if (product.image.includes(type)) {
            imageUrl = product.image.split(type).shift();
            $image.src = `${imageUrl}_150x${type}`;
        }
    });

    if (product.properties.Message && product.properties.Message.length > 0) {
        $message.innerText = `You've added a gift message`;
    }

    if (
        window.theme.customerType === 'trade'
    ) {
        $price.innerText = `${formatMoney(price, theme.moneyFormat)} ex. VAT`;
    } else {
        $price.innerText = formatMoney(price, theme.moneyFormat);
    }

    product.options_with_values.forEach(option => {
        const $newOption = document.createElement('p');
        const textNode = document.createTextNode(
            `${option.name}: ${option.value}`
        );
        $newOption.appendChild(textNode);
        $options.appendChild($newOption);
    });

    $quantityCount.innerText = product.quantity;
}

function addEventListeners() {
    window.addEventListener('cart.added', e => {
        resetPreviousProductDetails();
        buildProductDetails(e.detail.productData);
        $image.decode().then(() => {
            show();
        });
        setTimeout(() => {
            hide();
        }, 7000);
    });
    $addedToCartModalWrapper.addEventListener('click', () => {
        hide();
    });
}

if ($addedToCartModalWrapper) {
    addEventListeners();
}
